import React from 'react';

import Layout from 'components/layout/Layout';
import SEO from 'components/seo';
import StructureJumbotron from 'components/structure-jumbotron/StructureJumbotron';

const StructureRatesPage = () => (
  <Layout>
    <SEO title="Structure & Rates" />
    <StructureJumbotron />
  </Layout>
);

export default StructureRatesPage;
