import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

import RatesStructure from '../rates-structure/RatesStructure';

import './StructureJumbotron.scss';

const StructureJumbotron = () => {
  const { structurePicQuery } = useStaticQuery(graphql`
    query {
      structurePicQuery: allFile(
        filter: { absolutePath: { regex: "/(structure-rates.jpg)$/" } }
      ) {
        edges {
          node {
            name
            childImageSharp {
              fluid(maxWidth: 500, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);

  const structurePic = structurePicQuery.edges[0].node;

  return (
    <div className="structure-jumbotron">
      <div className="left">
        <div className="structure-container">
          <div className="structure-rates">STRUCTURE & RATES</div>
        </div>
        <Img
          fluid={structurePic.childImageSharp.fluid}
          fadeIn={true}
          alt={structurePic.name}
          draggable={false}
          className="structure-pic"
        />
        <RatesStructure />
        <div className="contact-me">
          <b>
            Do contact me if you are looking to start a new group class at your
            home or join an available class for the year.
          </b>
        </div>
      </div>
      <div className="right">
        <div className="small-group-classes">
          <span className="heading">SMALL GROUP CLASSES</span>
          <div className="content">
            <p>
              ‣ In my <b>small group classes (2-3 students)</b>, lessons will be
              held at one of the <b>student’s home or at a neutral location</b>.
              Students who are interested to join the group will have to travel
              to the agreed location for the session. New students may also pool
              in friends to start a new small group class.
            </p>
            <p>
              ‣ In my group classes, students of{' '}
              <b>similar caliber will be grouped</b> together and are expected
              to discuss and solve a series of complicated mathematical/economic
              questions, <b>challenging their understanding of concepts</b>.
              This ties in with the changing dynamics of the ongoing syllabus,
              as MOE strives to test students on concepts beyond the curriculum.
              I also provide
              <b>
                past school exam papers, fine-tuned worksheets and personally
                curated cheat sheets
              </b>{' '}
              for my students.
            </p>
            <p>
              ‣ I truly believe in <b>building my students&apos; confidence</b>{' '}
              through cultivating their soft skills in{' '}
              <b>communication within group settings</b>. My job extends beyond
              just teaching within the syllabus, I aim to mold my students into
              confident young adults in light of the changing dynamics of our
              economy.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StructureJumbotron;
