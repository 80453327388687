import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { RatesEdges, Rates } from 'types/types';
import './RatesStructure.scss';

const RatesStructure = () => {
  const { ratesQuery } = useStaticQuery(graphql`
    query {
      ratesQuery: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(RatesStructure.md)$/" } }
      ) {
        edges {
          node {
            frontmatter {
              rates {
                title
                subjects
                one
                pair
                group
              }
            }
          }
        }
      }
    }
  `);

  const {
    node: {
      frontmatter: { rates },
    },
  }: RatesEdges = ratesQuery.edges[0];

  return (
    <div className="rates-structure">
      <div>
        <span className="heading">RATES & STRUCTURE</span>
      </div>
      <div className="container">
        {rates.map((rate: Rates) => (
          <div className="section" key={rate.title}>
            <div className="title">{rate.title}</div>
            <div>({rate.subjects})</div>
            <div className="block">
              <div className="row">
                <span>- 1 to 1:</span>
                <span>${rate.one}/h</span>
              </div>
              <div className="row">
                <span>- pair of 2:</span>
                <span>${rate.pair}/h</span>
              </div>
              <div className="row">
                <span>- group of 3:</span>
                <span>${rate.group}/h</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RatesStructure;
